import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Grid } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './tabbed_menu_pannel.module.scss';
import { makeStyles } from '@mui/styles';
import { trackItem } from "@/utils";

export interface MenuItem {
  id?: string;
  name?: string;
  displayName?: string;
  url?: string;
  imageUrl?: string;
  availability?: string;
}

export interface Menu {
  name: string;
  items: MenuItem[];
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: any;
}

interface TabbedMenuPanelProps {
  menuJson: Menu[];
  deviceType?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  component={'div'} sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function TabbedMenuPanel({ deviceType, menuJson }: TabbedMenuPanelProps) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const useStyles = makeStyles({
    customTab: {
      minHeight: '25px !important',
      width: '100%',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      margin: '0',
    },
  });
  //classes prop provided by MUI components to override styles more directly
  const classes = useStyles();

  return (
    <Box component={'div'} className={styles.tabbedMenuPanelContainer}>
      <Tabs
        orientation="vertical"
        variant="standard"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label="Sub Header Menu Tabs"
        className={styles.tabsContainer}
      >
        {menuJson.map((category: Menu, index: number) => (
          <Tab
            key={index}
            className={styles.tab}
            classes={{ root: classes.customTab }} // to adjust the inside button height
            icon={<ChevronRightIcon fontSize="large" className={`${styles.tabIcon} ${value === index ? styles.showIcon : styles.hideIcon}`} />}
            iconPosition="end"
            label={category.name}
            onClick={(e) => {trackItem({
                id: category.name,
                event: category.name + '_subHeaderTabClick',
                name: category.name,
                tab: category.name
            })}}
          />
        ))}
      </Tabs>
      {menuJson.map((category: Menu, index: number) => (
        <TabPanel key={index} value={value} index={index} className={styles.tabPanel}>
          <Grid container className={styles.gridContainer}>
            {category.items.map((item, itemIndex) => (
              <Grid item xs={4} sm={4} md={2} key={itemIndex}>
                <Box component={'div'} display="flex" flexDirection="column" alignItems="center">
                  <a href={item.url} 
                    onClick={(e) => {trackItem({
                        id: item.id,
                        event: item.name + '_subHeaderMenuClick',
                        name: item.displayName,
                        tab: category.name,
                    })}} >
                    <div className={styles.itemIcon} >
                      <div className={styles.itemImage} style={{ backgroundImage: `url(${item.imageUrl})` }} />
                    </div>
                    <div className={styles.itemLabel}>{item.displayName}</div>
                  </a>
                </Box>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
}

export default TabbedMenuPanel;