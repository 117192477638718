import React, { useState, useMemo } from 'react';
import styles from './dropdownmenu.module.scss';
import TabbedMenuPanel, { Menu } from './tabbed_menu_panel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { trackItem } from '@/utils';

interface DropdownMenuProps {
    deviceType?: string;
    menuJson?: Menu[];
    menuTitle: string;
    menuUrl?: string;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ deviceType, menuJson, menuTitle, menuUrl }) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => setDropdownVisible(true);
    const handleMouseLeave = () => setDropdownVisible(false);
    // New click handler for mobile
    const handleClick = (menuTitle: string) => {
        setDropdownVisible(true);
        trackItem({
            event: 'headerMenuClick',
            name: menuTitle
        });
    };

    const memoizedTabbedMenuPanel = useMemo(() => {
        const subMenuClass = deviceType === 'mobile' ? styles.products_menu_mobile : styles.products_menu;
        const menuClass = isDropdownVisible ? `${subMenuClass} ${styles.visible}` : subMenuClass;
        return (
            <div className={menuClass}>
                {deviceType === 'mobile' && (
                    <CloseIcon
                        fontSize="large"
                        style={{
                            position: 'absolute',
                            top: 40,
                            right: 20,
                            cursor: 'pointer',
                            color: 'inherit',
                        }}
                        onClick={() => setDropdownVisible(false)}
                    />
                )}
                <TabbedMenuPanel menuJson={menuJson || []} deviceType={deviceType} />
            </div>
        );
    }, [menuJson, isDropdownVisible, deviceType]);

    return (
        <>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {deviceType === 'mobile'
                    ? <div id={"MenuTitle " + menuTitle} onClick={() => handleClick(menuTitle)} style={{ display: "flex", paddingLeft: "8px" }}>
                        <div style={{textTransform: "uppercase"}}>{menuTitle}</div>
                        <ChevronRightIcon fontSize="large" sx={{ color: "green" }} />
                      </div>
                    : <a href={menuUrl} id={"MenuTitle " + menuTitle}
                        onClick={(e) => {
                            trackItem({
                                event: 'headerMenuClick',
                                name: menuTitle
                            });
                        }}
                        style={{textTransform: "uppercase"}}
                      >{menuTitle}</a>
                }
                {memoizedTabbedMenuPanel}
            </div>
        </>
    );
};

export default DropdownMenu;